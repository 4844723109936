<template>
  <div>
    <div v-show="btnEditable" class="internalActionDiv">
      <q-btn-group outline >
        <c-btn 
          v-show="review.returnFlag === 'Y'"
          label="반려사유" 
          icon="info"
          @btnClicked="returnDialogOpen" />
        <c-btn 
          v-show="requestEditable"
          :isSubmit="isRequest"
          :url="requestUrl"
          :param="review"
          mappingType="PUT"
          label="검토요청" 
          icon="check"
          @beforeAction="requestReview"
          @btnCallback="requestReviewCallback" />
        <c-btn 
          v-show="completeEditable"
          :isSubmit="isComplete"
          :url="completeUrl"
          :param="review"
          mappingType="PUT"
          label="검토완료" 
          icon="check"
          @beforeAction="completeReview"
          @btnCallback="completeReviewCallback" />
        <c-btn 
          v-show="returnEditable"
          label="검토요청반려" 
          icon="check"
          @btnClicked="returnDialogOpen" />
        <!-- <c-btn 
          v-show="returnEditable"
          :isSubmit="isReturn"
          :url="returnUrl"
          :param="review"
          mappingType="PUT"
          label="검토요청반려" 
          icon="check"
          @beforeAction="returnReview"
          @btnCallback="returnReviewCallback" /> -->
      </q-btn-group>
    </div>
    <c-tab
      type="horizon"
      :tabItems.sync="tabItems"
      :inlineLabel="true"
      v-model="tab"
      :dense="true"
      :height="tabHeight"
    >
      <template v-slot:default="tab">
        <component
          :is="tab.component"
          :popupParam="popupParam"
          :review.sync="review"
          :isOld.sync="isOld"
          :disabled.sync="disabled"
          :btnEditable.sync="btnEditable"
          :contentHeight.sync="contentHeight"
          :fileLength.sync="fileLength"
          @getDetail="getDetail"
          @closePopup="closePopup"
        />
      </template>
    </c-tab>
    <q-dialog v-model="returnDialog.show" persistent>
      <q-card style="min-width: 450px;">
        <q-form ref="editForm2">
          <c-card title="반려 내용" class="cardClassDetailForm">
            <template slot="card-button">
              <q-btn-group outline >
                <c-btn 
                  v-show="returnEditable"
                  :isSubmit="isReturn"
                  :url="returnUrl"
                  :param="review"
                  mappingType="PUT"
                  label="반려" 
                  icon="check"
                  @beforeAction="returnReview"
                  @btnCallback="returnReviewCallback" />
                <c-btn 
                  label='LBLCLOSE' 
                  icon="cancel" 
                  @btnClicked="closeReturnDialog"
                />
              </q-btn-group>
            </template>
            <template slot="card-detail">
              <div class="col-12">
                <c-textarea
                  :editable="returnEditable"
                  :rows="5"
                  label=""
                  name="returnRemark"
                  v-model="review.returnRemark">
                </c-textarea>
              </div>
            </template>
          </c-card>
        </q-form>
      </q-card>
    </q-dialog>
  </div>
</template>

<script>
import selectConfig from '@/js/selectConfig';
import transactionConfig from '@/js/transactionConfig';
export default {
  name: 'chem-review-detail',
  props: {
    popupParam: {
      type: Object,
      default: () => ({
        mdmChemReviewId: '',
      }),
    },
    contentHeight: null,
  },
  data() {
    return {
      tab: 'info',
      review: {
        mdmChemReviewId: '',  // 사전검토 일련번호
        reviewRequestDate: '',  // 검토요청일
        plantCds: '',  // 사업장코드
        materialCd: '',  // 자재코드
        materialName: '',  // 자재명(KOR)
        materialNameEn: '',  // 자재명(ENG)
        deliveryVendorCd: null,  // 공급업체 코드
        deliveryVendorName: '',  // 공급업체명
        mfgVendorCd: null,  // 제조업체 코드
        mfgVendorName: '',  // 제조업체명
        mdmChemReviewStepCd: null,  // 사전검토 진행상태
        propertiesStateCd: null,  // 성상-공통코드
        fugacity: '',  // 비산성(고체)
        boilPoint: '',  // 끊는점(액체,기체)
        meltingPoint: '',  // 녹는점
        moleWeight: '',  // 분자량
        usageCd: null,  // 용도-회사별 공통코드
        usageEtc: '',  // 용도 기타
        productCategoryCd: null,  // 제품구분
        keepFacilityFlag: 'N',  // 보관시설 유무
        maxKeepAmount: '',  // 최대보관량
        storageFacilityFlag: 'N',  // 저장시설 유무
        maxStorageAmount: '',  // 최대저장량
        manufacturingFacilitiesFlag: 'N',  // 제조 사용시설 여부
        nanoManufacturingFlag: 'N',  // 나노물질 제조 여부
        nanoIncomeFlag: 'N',  // 나노물질 수입 여부
        nanoNoneApplicableFlag: 'N',  // 나노물질 해당없음 여부
        nanoUnknownFlag: 'N',  // 나노물질 모름 여부
        inputDissolutionTank: '',  // 투입 용해조
        productManufClassCd: null,  // 제품제조구분 코드
        chemIngredientsCd: null,  // 구성성분 작성근거
        hsNo: '',  // HS번호
        specificGravity: '',  // 비중
        casNo: '',  // CAS No.
        serialNo: '',  // 고유번호(유독물번호or고시번호)
        keNo: '',  // KE 번호
        psmFlag: 'N',  // PSM 대상 여부
        mixFlag: 'N',  // 물질구성(단일 N/혼합 Y)
        licensingFlag: 'N',  // 인허가대상 물질 여부 Y/N
        dangerFlag: 'N',  // 위험물 여부Y/N
        makeFlag: 'N',  // 제품분류(제조)
        impFlag: 'N',  // 제품분류(수입)
        buyFlag: 'N',  // 제품분류(구매)
        usingFlag: 'N',  // 제품분류(사용)
        expFlag: 'N',  // 제품분류(수출)
        salesFlag: 'N',  // 제품분류(판매)
        toxicPoisonFlag: 'N',  // 유해화학물질포함여부(유독물질)
        toxicPermitFlag: 'N',  // 유해화학물질포함여부(허가물질)
        toxicLimitFlag: 'N',  // 유해화학물질포함여부(제한물질)
        toxicProhibitFlag: 'N',  // 유해화학물질포함여부(금지물질)
        remarks: '',  // 비고
        returnRemark: '',  // 반려사유
        returnFlag: '',  // 반려여부
        regUserId: '',  // 등록자 ID
        chgUserId: '',  // 수정자 ID
        subs: [],
        subsRegul: [],
        prodReguls: [],
        bills: [],
        checks: [],
      },
      fileLength: {
        msds: 0,
        ingrdient: 0,
      },
      returnDialog: {
        show: false,
      },
      editable: true,
      isRequest: false,
      isComplete: false,
      isReturn: false,
      detailUrl: '',
      uploadedFilesUrl: '',
      requestUrl: '',
      completeUrl: '',
      returnUrl: '',
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  computed: {
    tabHeight() {
      return String(this.contentHeight - 36);
    },
    isOld() {
      return Boolean(this.popupParam.mdmChemReviewId)
    },
    disabled() {
      return this.review.mdmChemReviewStepCd === 'MCR0000010'
    },
    requestEditable() {
      return this.editable
        && !this.disabled
        && this.isOld
        && this.review.mdmChemReviewStepCd === 'MCR0000001'
    },
    completeEditable() {
      return this.editable
        && !this.disabled
        && this.isOld
        && this.review.mdmChemReviewStepCd === 'MCR0000005'
        && this.review.checks.length === this.$_.filter(this.review.checks, { 
            mdmChemReviewCheckStepCd: 'CRC0000010',
            mdmChemReviewResultCd: 'MCRR000005' 
          }).length
    },
    returnEditable() {
      return this.editable
        && !this.disabled
        && this.isOld
        && this.review.mdmChemReviewStepCd === 'MCR0000005'
        && this.review.checks.length === this.$_.filter(this.review.checks, (item) => {
          return Boolean(item.reviewDt)
        }).length
        && this.review.checks.length !== this.$_.filter(this.review.checks, { 
            mdmChemReviewCheckStepCd: 'CRC0000010',
            mdmChemReviewResultCd: 'MCRR000005' 
          }).length
    },
    btnEditable() {
      return this.requestEditable
        || this.completeEditable
        || this.returnEditable
        || this.review.returnFlag === 'Y'
    },
    tabItems() {
      let _items = [
        { name: 'info', icon: 'info', label: 'LBLBASEINFO', component: () => import(`${'./chemReviewInfo.vue'}`) },
        // { name: 'check', icon: 'info', label: '검토결과', component: () => import(`${'./chemReviewCheck.vue'}`) },
      ]
      if (this.isOld) {
        _items.push({ name: 'etc', icon: 'settings_applications', label: '화학자재 상세정보', component: () => import(`${'./chemReviewEtc.vue'}`) },)
        _items.push({ name: 'regul', icon: 'info', label: '구성물질 정보/규제', component: () => import(`${'./chemReviewComponents.vue'}`) },)
      }
      return _items;
    } 
  },
  methods: {
    init() {
      // role setting
      this.editable = this.$route.meta.editable;
      // url setting
      this.detailUrl = selectConfig.mdm.mam.review.get.url;
      this.uploadedFilesUrl = selectConfig.com.upload.fileList.url
      this.requestUrl = transactionConfig.mdm.mam.review.request.url;
      this.completeUrl = transactionConfig.mdm.mam.review.complete.url;
      this.returnUrl = transactionConfig.mdm.mam.review.return.url;
      // code setting
      // list setting
      this.getDetail();
    },
    getDetail() {
      if (this.isOld) {
        this.$http.url = this.$format(this.detailUrl, this.popupParam.mdmChemReviewId);
        this.$http.type = 'GET';
        this.$http.request((_result) => {
          this.review = _result.data;
          // 상세팝업 최초작성/최근수정 정보 노출
          this.$emit('setRegInfo', _result.data)
          this.getFileList('CHEM_REVIEW_MSDS');
          this.getFileList('CHEM_REVIEW_INGREDIENT_STATEMENT');

          this.review.bills = [];
          if (this.review.prodReguls && this.review.prodReguls.length > 0) {
            this.review.bills = this.$_.map(this.review.prodReguls, (value) => {
              return {
                regulationBillCd: value['regulationBillCd'],
                regulationBillName: value['regulationBillName'],
                reguls: [],
              }
            });
            this.review.bills = this.$_.uniqBy(this.review.bills, (item) => {
              return item.regulationBillCd
            })
            this.$_.forEach(this.review.bills, bill => {
              this.$set(bill, 'reguls', this.$_.filter(this.review.prodReguls, { regulationBillCd: bill.regulationBillCd }))
            })
          }
        },);
      } else {
        // 신규등록인 경우
        this.$set(this.review, 'reviewRequestDate', this.$comm.getToday())
        this.$set(this.review, 'plantCds', this.$store.getters.user.plantCd);
        this.$set(this.review, 'mdmChemReviewStepCd', 'MCR0000001'); // 직성중

        /**
         * 검토자 목록 : default X, 사용자가 직접 입력하게 처리
         * 
         * 안전/보건/환경 관리자(권한)
         */
      }
    },
    getFileList(taskClassCd) {
      this.$http.url = this.uploadedFilesUrl;
      this.$http.type = 'GET';
      this.$http.param = {
        taskClassCd: taskClassCd,
        taskKey: this.popupParam.mdmChemReviewId,
      }
      this.$http.request((_result) => {
        if (_result.data) {
          let prop = taskClassCd === 'CHEM_REVIEW_MSDS' ? 'msds' : 'ingrdient'
          this.$set(this.fileLength, prop, _result.data.length)
        }
      },);
    },
    closePopup() {
      this.$emit('closePopup')
    },
    returnDialogOpen() {
      // 반려사유 팝업 열기
      this.returnDialog.show = true;
    },
    closeReturnDialog() {
      this.returnDialog.show = false;
      Object.assign(this.$data.returnDialog, this.$options.data().returnDialog);
    },
    validChecker() {
      return new Promise(resolve => {
        if (this.review.checks && this.review.checks.length > 0) {
          resolve(true);
        } else {
          window.getApp.$emit('ALERT', {
            title: 'LBLGUIDE', // 안내
            message: '검토자가 지정되지 않았습니다.', // 검토자가 지정되지 않았습니다.
            type: 'warning', // success / info / warning / error
          });
          resolve(false);
        }
      });
    },
    validBaseInfo() {
      return new Promise(resolve => {
        if (this.review.reviewRequestDate && this.review.plantCds) {
          resolve(true);
        } else {
          window.getApp.$emit('ALERT', {
            title: 'LBLGUIDE', // 안내
            message: '기본정보 [자재, 검토요청일, 관련사업장] 정보는 필수값입니다.', // 기본정보 [자재, 검토요청일, 관련사업장] 정보는 필수값입니다.
            type: 'warning', // success / info / warning / error
          });
          resolve(false);
        }
      });
    },
    validSubs() {
      return new Promise(resolve => {
        if (this.review.subs && this.review.subs.length > 0) {
          resolve(true);
        } else {
          window.getApp.$emit('ALERT', {
            title: 'LBLGUIDE', // 안내
            message: '구성물질을 하나 이상 등록하시기 바랍니다.', // 구성물질을 하나 이상 등록하시기 바랍니다.
            type: 'warning', // success / info / warning / error
          });
          resolve(false);
        }
      });
    },
    validIngrdientStatemen() {
      return new Promise(resolve => {
        let limitRepvalSum = 0;
        this.$_.forEach(this.review.subs, sub => {
          limitRepvalSum += (sub.limitRepval && !isNaN(sub.limitRepval) ? Number(sub.limitRepval) : 0)
        })
        if (limitRepvalSum === 100) {
          resolve(true);
        } else {
          if (this.fileLength.ingrdient > 0) {
            resolve(true);
          } else {
            window.getApp.$emit('ALERT', {
              title: 'LBLGUIDE', // 안내
              message: '구성물질의 함유량 합이 100%가 안될시에 성분내역서가 첨부되어야 합니다.', // 구성물질의 함유량 합이 100%가 안될시에 성분내역서가 첨부되어야 합니다.
              type: 'warning', // success / info / warning / error
            });
            resolve(false);
          }
        }
      });
    },
    validMsds() {
      return new Promise(resolve => {
        if (this.fileLength.msds > 0) {
          resolve(true);
        } else {
          window.getApp.$emit('ALERT', {
            title: 'LBLGUIDE', // 안내
            message: 'MSDS 첨부하시기 바랍니다.', // MSDS 첨부하시기 바랍니다.
            type: 'warning', // success / info / warning / error
          });
          resolve(false);
        }
      });
    },
    confirmRequest() {
      return new Promise(resolve => {
        window.getApp.$emit('CONFIRM', {
          title: 'LBLCONFIRM',
          message: '검토자에게 검토요청하시겠습니까?',
          // TODO : 필요시 추가하세요.
          type: 'info', // success / info / warning / error
          // 확인 callback 함수
          confirmCallback: () => {
            resolve(true);
          },
          // 취소 callback 함수
          cancelCallback: () => {
            resolve(false);
          },
        });
      });
    },
    requestValue() {
      return new Promise(resolve => {
        this.isRequest = !this.isRequest
        resolve(true);
      });
    },
    requestReview() {
      /**
       * 1. 검토자가 지정되었는지?
       * 2. 기본정보 : 자재, 검토요청일, 관련사업장
       * 3. 구성물질이 하나 이상 들어가 있는지?
       * 4. 구성물질의 함유량의 합이 100%가 안될시 성분내역서 필수
       * 5. MSDS 첨부 
       */
      let promises = [
        {
          func: this.validChecker,
        },
        {
          func: this.validBaseInfo,
        },
        {
          func: this.validSubs,
        },
        {
          func: this.validIngrdientStatemen,
        },
        {
          func: this.validMsds,
        },
        {
          func: this.confirmRequest
        },
        {
          func: this.requestValue
        },
      ];
      this.$comm.orderedPromise(promises);
    },
    requestReviewCallback() {
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      this.getDetail();
    },
    completeReview() {
      /**
       * 현재는 유효성 넣지 않음
       */
      window.getApp.$emit('CONFIRM', {
        title: 'LBLCONFIRM',
        message: 'MSGCOMPLETE', // 완료하시겠습니까?,
        // TODO : 필요시 추가하세요.
        type: 'info', // success / info / warning / error
        // 확인 callback 함수
        confirmCallback: () => {
          this.review.chgUserId = this.$store.getters.user.userId

          this.isComplete = !this.isComplete
        },
        // 취소 callback 함수
        cancelCallback: () => {
        },
      });
    },
    completeReviewCallback() {
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      // 계획 상세 조회
      this.getDetail();
    },
    returnReview() {
      if (!this.review.returnRemark) {
        window.getApp.$emit('ALERT', {
          title: 'LBLGUIDE', // 안내
          message: '반려사유를 입력하십시오.', // 반려사유를 입력하십시오.
          type: 'warning', // success / info / warning / error
        });
        return;
      }
      window.getApp.$emit('CONFIRM', {
        title: 'LBLCONFIRM',
        message: '검토요청 반려하시겠습니까?',
        // TODO : 필요시 추가하세요.
        type: 'info', // success / info / warning / error
        // 확인 callback 함수
        confirmCallback: () => {
          this.review.chgUserId = this.$store.getters.user.userId

          this.isReturn = !this.isReturn
        },
        // 취소 callback 함수
        cancelCallback: () => {
        },
      });
    },
    returnReviewCallback() {
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      this.returnDialog.show = false;
      // 계획 상세 조회
      this.getDetail();
    },
  }
};
</script>
<style lang="sass">
.internalActionDiv
  width:100%
  height: 30px
  .q-btn-group
    position: absolute
    right: 0px
    padding-right: 10px
</style>